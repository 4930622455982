<template>
  <div>
    <b-card
      no-body
    >
      <template #header>
        <b-container fluid>
          <b-row class="lg">
            <b-col
              md="6"
              class="mb-1"
              align-self="center"
            >
              <b-list-group flush>
                <b-list-group-item><strong>Site : </strong>{{ line.siteName }}</b-list-group-item>
                <b-list-group-item><strong>Building : </strong>{{ line.buildingName }}</b-list-group-item>
                <b-list-group-item><strong>Line : </strong>{{ line.lineName }}</b-list-group-item>
                <b-list-group-item>
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-input-group
                      class="vertical-align-middle"
                    >
                      <b-form-radio-group
                        id="btn-radios-2"
                        v-model="checked"
                        :options="chartOptions"
                        :aria-describedby="ariaDescribedby"
                        button-variant="outline-primary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                      />
                      <b-form-checkbox
                        v-if="checked === 'freq'"
                        v-model="quickDraw"
                        class="ml-1"
                        name="check-button"
                        switch
                      >
                        <b>{{quickDraw ? 'Disable':'Enable' }} Magnitude Color Coding</b>
                      </b-form-checkbox>
                    </b-input-group>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="line.lineIsMaintenance"
                    switch
                    inline
                    name="linemaintenance"
                    @change="setMaintenance"
                  >
                    Line Maintenance
                  </b-form-checkbox>
                </b-list-group-item>

                <b-form-group class="m-1">
                  <b-input-group size="sm">
                    <date-picker
                      v-model="timeRange"
                      type="datetime"
                      outlined
                      value-type="timestamp"
                      size="sm"
                      style="width: 370px"
                      range
                      :disabled="isBusy"
                      @clear="clearTimeRange"
                      @close="validateTimeRange"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        :disabled="isBusy"
                        size="sm"
                        @click="getItems"
                      >
                        Request Data
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        :disabled="isBusy"
                        size="sm"
                        @click="downLoadFile"
                      >
                        <b-icon
                          icon="download"
                        />
                        DownLoad Data
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-list-group>
            </b-col>
            <b-col
              v-if="lineData"
              md="6"
              class="mb-1"
            >
              <line-speed-card
                :key="`availability_${mdmStatus}`"
                :status="mdmStatus"
              />
            </b-col>
            <!-- <b-col
              v-if="lineData"
              md="3"
              class="mb-1"
            >
              <line-breakdown-card
                :key="`breakdowns_${lineData.length}`"
                :line-data="lineData"
              />
            </b-col> -->
          </b-row>
        </b-container>
      </template>
    </b-card>
    <b-card
      v-if="checked === 'freq'"
      :key="`MDM_${MDM.assetID}_${timeRange[0]}_${timeRange[1]}`"
      no-body
      class="p-1"
    >
      <b-row
        size="lg"
      >
        <b-col
          lg="2"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <asset
            :asset="MDM"
          />
        </b-col>
        <b-col
          lg="10"
          class="mb-0"
          align-v="center"
        >
          <b-card
            :style="`width: 100%; height: ${checked === 'freq' ? '20rem':'10rem'};`"
            class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
          >
            <app-echart-scatter
              v-if="checked === 'freq' && MDM.name === 'Main Drive Motor 1'"
              :style-obj="{width: '100%', height: '20rem'}"
              :option-data="getStdOptions(MDM.assetID)"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-for="asset in assets"
      :key="asset.assetID"
      no-body
      class="p-1"
    >
      <b-row
        size="lg"
      >
        <b-col
          lg="2"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <asset
            :asset="asset"
          />
        </b-col>
        <b-col
          lg="10"
          class="mb-0"
          align-v="center"
        >
          <b-card
            :style="`width: 100%; height: ${checked === 'freq' ? '20rem':'10rem'};`"
            class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
          >
            <dominant-frequency-card
              v-if="checked === 'freq'"
              :key="`dominant_${asset.assetID}_${timeRange[0]}_${timeRange[1]}_${quickDraw}`"
              :asset-i-d="asset.assetID"
              :is-busy="isBusy"
              :time-range="timeRange"
              :large-data="quickDraw"
            />
            <app-echart-scatter
              v-else
              :style-obj="{width: '100%', height: '10rem'}"
              :option-data="getOptions(asset.assetID)"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BContainer, BListGroup, BListGroupItem, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BIcon, BIconDownload, BFormRadioGroup, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppEchartScatter from '@core/components/charts/echart/AppEchartScatter.vue'
import DatePicker from 'vue2-datepicker'
import Papa from 'papaparse'
import assetFactoryStore from '../assetsLogStore'
// import LineAvailabilityCard from './LineAvailabilityCard.vue'
// import LineBreakdownCard from './LineBreakdownCard.vue'
import DominantFrequencyCard from './DominantFrequencyCard.vue'
import LineSpeedCard from './LineSpeedCard.vue'
import Asset from '../../../dashboard/factory/Asset.vue'
import 'vue2-datepicker/index.css'
import factory from '@/store/factory'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BContainer,
    BListGroup,
    BListGroupItem,
    Asset,
    AppEchartScatter,
    LineSpeedCard,
    DatePicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BIcon,
    BIconDownload,
    BFormRadioGroup,
    DominantFrequencyCard,
    BFormCheckbox,
  },
  setup() {
    const FACTORY_APP_STORE_MODULE_NAME = 'app-factory'
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'
    const toast = useToast()
    const line = ref({})
    const assets = ref([])
    const lineData = ref([])
    const { lineID } = router.currentRoute.params
    const items = ref(new Map())
    const isBusy = ref(false)
    const checked = ref('status')
    const now = new Date()
    const defaultTimeRange = ref([new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() - 1).getTime(), new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - 1).getTime()])
    const timeRange = ref(defaultTimeRange.value)
    let range = [...timeRange.value]
    const minStdDev = ref(0)
    const maxStdDev = ref(1)
    const MDM = ref({})
    const mdmStatus = ref(0)
    const quickDraw = ref(true)


    const chartOptions = [
      { text: 'Status', value: 'status' },
      { text: 'Standard Deviation', value: 'stddev' },
      { text: 'Dominant Frequency', value: 'freq' },
    ]



    const groupBy = (list, keyGetter) => {
      const map = new Map()
      const stdDevArray = []
      list.forEach(item => {
        stdDevArray.push(item.stdDev)
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [{ msEpoch: item.msEpoch, status: item.status, stdDev: item.stdDev }])
        } else {
          collection.push({ msEpoch: item.msEpoch, status: item.status, stdDev: item.stdDev })
        }
      })
      minStdDev.value = Math.min(...stdDevArray)
      maxStdDev.value = Math.max(...stdDevArray)
      return map
    }
    const updateAsset = () => {
      if (assets.value.length > 0) {
        assets.value.map(asset => {
          if (items.value.get(asset.assetID)) {
            const statusArr = items.value.get(asset.assetID)
            // eslint-disable-next-line no-param-reassign
            asset.status = statusArr[statusArr.length - 1].status
          }
          return asset
        })
      }
      MDM.value = assets.value.find(({ name }) => name === 'Main Drive Motor 1')
    }

    const setMaintenance = () => {
      const data = {
        isMaintenance: line.value.lineIsMaintenance,
        id: line.value.lineID,
      }
      store.dispatch('app-factory/updateFactory', data).then (() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Line Maintenance Status Updated',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Updating Line Maintenance Status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    }

    const getItems = () => {
      isBusy.value = true
      store
        .dispatch('app-asset-log/getLineStatus', { lineID, params: { startTime: timeRange.value[0], endTime: timeRange.value[1] } })
        .then(response => {
          lineData.value = response.data
          // sort by assetID
          lineData.value.sort((a, b) => a.assetID - b.assetID)
          if (lineData.value.length > 0) {
            items.value = groupBy(response.data, factory => factory.assetID)
            MDM.value = lineData.value.find(({ name }) => name === 'Main Drive Motor 1')
            if (MDM.value) {
              // console.log(MDM.value)
              console.log(line.value)
              mdmStatus.value = MDM.value.status
              const statusArr = items.value.get(MDM.value.assetID)
              // eslint-disable-next-line no-param-reassign
              mdmStatus.value = statusArr[statusArr.length - 1].status
            }
          }
          // after.value = response.data[0].msEpoch
          isBusy.value = false
          updateAsset()
        })
        .catch(e => {
          console.log(e)
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const getOptions = assetID => {
      const minX = range[0]
      const maxX = range[1]
      const data = items.value.get(assetID)
      if (data) {
        const seriesData = checked.value === 'status' ? data.map(item => [item.msEpoch, item.status]) : data.map(item => [item.msEpoch, item.stdDev])
        const min = checked.value === 'status' ? -1 : minStdDev.value.toFixed(3)
        const max = checked.value === 'status' ? 3 : maxStdDev.value.toFixed(3)
        const interval = checked.value === 'status' ? 1 : 0.1
        const splitNumber = checked.value === 'status' ? 1 : 0.001
        const statusYAxis = {
          type: 'value',
          min,
          max,
          scale: false,
          splitNumber,
          interval,
        }
        // const stdDevYAxis = {
        //   type: 'log',
        //   logbase: 10,
        //   min: 0.001,
        // }
        const stdDevArray = data.map(item => item.stdDev)
        const stdDevYAxis = {
          type: 'value',
          min: ((Math.min(...stdDevArray)) - 0.005).toFixed(5),
          max: ((Math.max(...stdDevArray)) + 0.005).toFixed(5),
          scale: false,
          splitNumber,
          interval,
        }
        const yAxis = checked.value === 'status' ? statusYAxis : stdDevYAxis
        return {
          replaceMerge: ['xAxis', 'yAxis', 'series'],
          grid: {
            left: '5%',
            top: '5%',
            right: '2%',
            bottom: '25%',
          },
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: false,
              },
              saveAsImage: {
                pixelRatio: 2,
              },
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            position(pos, params, el, elRect, size) {
              const obj = { top: 10 }
              obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30
              return obj
            },
            extraCssText: 'width: 170px',
          },
          axisPointer: {
            link: { xAxisIndex: 'all' },
            label: {
              backgroundColor: '#777',
            },
          },
          dataZoom: [
            {
              show: true,
              realtime: true,
              start: 0,
              end: 100,
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              realtime: true,
              start: 0,
              end: 100,
              xAxisIndex: [0],
            },
          ],
          xAxis: {
            min: minX,
            max: maxX,
            type: 'time',
            axisLine: {
              onZero: false,
            },
            axisLabel: {
              formatter(value) {
                return msEpochToDate(value)
              },
            },
          },
          yAxis,
          visualMap: {
            top: 50,
            right: 10,
            min,
            max,
            show: false,
            inRange: {
              color: ['grey', '#4b4b4b', '#ff9f43', '#28c76f', '#ea5455'],
            },
            calculable: true,
          },
          series: [
            {
              symbolSize: 4,
              data: seriesData,
              type: 'scatter',
              tooltip: {
                formatter(param) {
                  return [
                    `Open: ${param.data[0]}<br/>`,
                    `Close: ${param.data[1]}<br/>`,
                  ].join('')
                },
              },
            },
          ],
        }
      }
      return {
        replaceMerge: ['xAxis', 'yAxis', 'series'],
        title: {
          show: true,
          textStyle: {
            color: 'grey',
            fontSize: 20,
          },
          text: 'No Data',
          left: 'center',
          top: 'center',
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [],
      }
    }

    const getStdOptions = assetID => {
      const minX = range[0]
      const maxX = range[1]
      const data = items.value.get(assetID)
      if (data) {
        const seriesData = data.map(item => [item.msEpoch, item.stdDev])
        const min = minStdDev.value.toFixed(3)
        const max = maxStdDev.value.toFixed(3)
        const interval = 0.1
        const splitNumber = 0.001
        const statusYAxis = {
          type: 'value',
          min,
          max,
          scale: false,
          splitNumber,
          interval,
        }
        // const stdDevYAxis = {
        //   type: 'log',
        //   logbase: 10,
        //   min: 0.001,
        // }
        const stdDevArray = data.map(item => item.stdDev)
        const stdDevYAxis = {
          type: 'value',
          min: Math.min(...stdDevArray).toFixed(5),
          max: Math.max(...stdDevArray).toFixed(5),
          scale: false,
          splitNumber,
          interval,
        }
        const yAxis = stdDevYAxis
        return {
          replaceMerge: ['xAxis', 'yAxis', 'series'],
          grid: {
            left: '5%',
            top: '5%',
            right: '2%',
            bottom: '45%',
          },
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: false,
              },
              saveAsImage: {
                pixelRatio: 2,
              },
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            position(pos, params, el, elRect, size) {
              const obj = { top: 10 }
              obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30
              return obj
            },
            extraCssText: 'width: 170px',
          },
          axisPointer: {
            link: { xAxisIndex: 'all' },
            label: {
              backgroundColor: '#777',
            },
          },
          dataZoom: [
            {
              show: true,
              realtime: true,
              start: 0,
              end: 100,
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              realtime: true,
              start: 0,
              end: 100,
              xAxisIndex: [0],
            },
          ],
          xAxis: {
            min: minX,
            max: maxX,
            type: 'time',
            axisLine: {
              onZero: false,
            },
            axisLabel: {
              formatter(value) {
                return msEpochToDate(value)
              },
            },
          },
          yAxis,
          visualMap: {
            top: 50,
            right: 10,
            min,
            max,
            show: false,
            inRange: {
              color: ['grey', '#4b4b4b', '#ff9f43', '#28c76f', '#ea5455'],
            },
            calculable: true,
          },
          series: [
            {
              symbolSize: 4,
              data: seriesData,
              type: 'scatter',
              tooltip: {
                formatter(param) {
                  return [
                    `Open: ${param.data[0]}<br/>`,
                    `Close: ${param.data[1]}<br/>`,
                  ].join('')
                },
              },
            },
          ],
        }
      }
      return {
        replaceMerge: ['xAxis', 'yAxis', 'series'],
        title: {
          show: true,
          textStyle: {
            color: 'grey',
            fontSize: 20,
          },
          text: 'No Data',
          left: 'center',
          top: 'center',
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [],
      }
    }

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)
    if (!store.hasModule(FACTORY_APP_STORE_MODULE_NAME)) store.registerModule(FACTORY_APP_STORE_MODULE_NAME, factory)

    const fetchThisLine = () => {
      store.dispatch('app-asset-log/fetchThisLine', lineID)
        .then(response => {
          const { data } = response
          // eslint-disable-next-line prefer-destructuring
          line.value = data[0]
          assets.value = data
          // sort by assetID
          assets.value.sort((a, b) => a.assetID - b.assetID)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Asset Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchThisLine()

    getItems()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(FACTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(FACTORY_APP_STORE_MODULE_NAME)
    })

    const clearTimeRange = () => {
      timeRange.value = [...defaultTimeRange.value]
      range = [...timeRange.value]
      getItems()
    }

    const validateTimeRange = () => {
      if (timeRange.value.length === 2) {
        // check is range difference is less than 72 hour
        const diff = timeRange.value[1] - timeRange.value[0]
        if (diff > (720 * 3600000)) {
          toast('Please select a range of maximum 7 days', {
            title: 'Invalid range',
            variant: 'danger',
            solid: true,
          })
          timeRange.value = [...defaultTimeRange.value]
          range = [...timeRange.value]
        } else {
          range = [...timeRange.value]
        }
      } else {
        timeRange.value = [...defaultTimeRange.value]
        range = [...timeRange.value]
      }
    }

    const toCSV = () => Papa.unparse(lineData.value)

    const downLoadFile = () => {
      console.log(lineData.value)
      const content = toCSV()
      const blob = new Blob([content], { type: 'application/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${line.value.lineName}.csv`
      link.click()
      URL.revokeObjectURL(link.href)
    }


    return {
      assets,
      lineData,
      line,
      lineID,
      items,
      isBusy,
      timeRange,
      defaultTimeRange,
      checked,
      mdmStatus,
      validateTimeRange,
      getOptions,
      getItems,
      clearTimeRange,
      downLoadFile,
      convertDate: msEpochToDate,
      chartOptions,
      getStdOptions,
      quickDraw,
      MDM,
      setMaintenance
    }
  },
}
</script>
