<template>
  <div :style="styleObj">
    <e-charts
      ref="line"
      autoresize
      :option="optionData"
      theme="theme-color"
      :style="styleObj"
      auto-resize
      :update-options="{notMerge:true}"
      v-on:brushEnd="chartSelected"
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { ScatterChart, EffectScatterChart, LineChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, ToolboxComponent, VisualMapComponent, BrushComponent, MarkAreaComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  ScatterChart,
  EffectScatterChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  VisualMapComponent,
  BrushComponent,
  MarkAreaComponent,
  LineChart,
])

export default {
  emits: ['brushEnd'],
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
  },
  data() {
    return {
      option: {
        grid: {
          width: '95%',
          left: '30px',
          right: '40px',
          containLabel: false,
        },
        legend: {
          enable: true,
          left: '0',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        xAxis: {
          boundaryGap: false,
          scale: true,
          splitNumber: 10,
          min: 0,
        },
        yAxis: {
          splitLine: { show: false },
          scale: true,
        },
        series: this.optionData.series,
      },
    }
  },

  methods: {
    chartSelected(e) {
      console.log(e)
      const startData = e.areas[0].coordRange[0]
      const endData = e.areas[0].coordRange[1]
      console.log(startData, endData)
      this.$emit('brushEnd', startData, endData)
    },
  },
}
</script>
