<template>
  <div :style="styleObj">
    <e-charts
      ref="line"
      autoresize
      :option="optionData"
      :style="styleObj"
      auto-resize
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, ToolboxComponent, VisualMapComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  VisualMapComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          left: 10,
          bottom: '0',
        },
        series: this.series,
      },
    }
  },
}
</script>
