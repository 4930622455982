<template>
  <b-link
    :to="{ name: 'apps-assets-log', params: { 'assetID': asset.assetID } }"
  >

    <b-card
      :bg-variant="`${statusMapping()}`"
      :sub-title-text-variant="`${subTitleStatusMapping()}`"
      style="width: 15rem; height: 10rem;"
      class="border rounded  flex-fill mb-0 mr-1 mt-2 text-center badge-minimal  position-relative d-inline-block"
    >
      <template #default>
        <div
          style="position: absolute; top: 5px !important; right: 5px !important;"
        >
        <feather-icon
          badge=" "
          border-variant="light"
          :badge-classes="`bg-${connectedVariant(asset.lastUpdatedTime) ? 'success' : 'danger'} border-light badge-up-jonas`"
          icon=""
          class="text-body"
          size="21"

        />
        </div>
        
        <div
          style="position: absolute; top: 5px !important; right: 5px !important;"
        >
          <b-link
            :to="{ name: 'apps-assets-alerts', params: { 'assetID': asset.assetID } }"
          >
            <feather-icon
              v-if="getAlertsCount()"
              icon=""
              size="21"
              class="text-secondary"
              badge-classes="border-light bg-warning text-dark"
              :badge="`${getAlertsCount()}`"
            />
          </b-link>
          <!-- <asset-notifications
            :asset="asset"
          /> -->
        </div>
        <b-card-title> <strong :class="`text-${titleStatusMapping()}`">{{ asset.name }} </strong> </b-card-title>
        <b-card-sub-title
          :sub-title-text-variant="`${subTitleStatusMapping()}`"
        >
          {{ asset.assetID +'-'+ asset.deviceID }}
          <span>{{ asset.axis ? asset.axis +':'+ asset.stdDev.toFixed(4) :'' }}</span> <br>
          <span>Regime:{{ asset.regime ? asset.regime: 'NA' }}</span>
        </b-card-sub-title>
      </template>
    </b-card>

  </b-link>
</template>
<script>
import {
  BCard, BLink, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { isConnected } from '@core/utils/filter'

export default ({
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  methods: {
    statusMapping() {
      let val = 'seconday'
      const { status } = this.asset
      if (status === 0) {
        val = 'dark'
      } else if (status === 2) {
        val = 'success'
      } else if (status === 1) {
        val = 'warning'
      } else if (status === 3) {
        val = 'danger'
      } else if (status === -1) {
        val = 'white'
      }
      return val
    },
    subTitleStatusMapping() {
      let val = 'seconday'
      const { status } = this.asset
      if (status === 0) {
        val = 'white'
      } else if (status === 2) {
        val = 'white'
      } else if (status === 1) {
        val = 'white'
      } else if (status === 3) {
        val = 'white'
      } else if (status === -1) {
        val = 'grey'
      }
      return val
    },
    titleStatusMapping() {
      let val = 'seconday'
      const { status } = this.asset
      if (status === 0) {
        val = 'white'
      } else if (status === 2) {
        val = 'dark'
      } else if (status === 1) {
        val = 'dark'
      } else if (status === 3) {
        val = 'dark'
      } else if (status === -1) {
        val = 'dark'
      }
      return val
    },
    getAlertsCount() {
      const { faults } = this.asset
      if (faults) {
        if (faults.includes(1001)) {
          return faults.length - 1
        }
        return faults.length
      }
      return 0
    },
    connectedVariant(val) {
      return isConnected(val)
    },
  },
})
</script>
