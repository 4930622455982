import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllAssets(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchThisAssets(ctx, assetID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/all?assetID=${assetID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchThisLine(ctx, lineID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/all?lineID=${lineID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssetLogs(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/events', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssetdominantFreqLogs(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/dominantfrequencyfft?assetID=${params.assetID}&startTime=${params.startTime}&endTime=${params.endTime}`, {
            timeout: 200000, 'Ocp-Apim-Trace': true, 'Accept-Encoding': 'gzip',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssetdominantFreqGraph(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/training/dominantfrequencymagnitudefft?assetID=${params.assetID}&startTime=${params.startTime}&endTime=${params.endTime}`, params, {
            timeout: 200000, 'Ocp-Apim-Trace': true, maxContentLength: 100000000, maxBodyLength: 1000000000, 'Accept-Encoding': 'gzip',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFFTData(ctx, path) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/fft/${path.path}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch3DFFTData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/fft', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevicesByStatus(ctx, status) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/?status=${status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNewAsset(ctx, asset) {
      return new Promise((resolve, reject) => {
        axios
          .put('/asset/', asset)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAsset(ctx, asset) {
      return new Promise((resolve, reject) => {
        axios
          .patch('/asset/', asset)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTelemetries(ctx, deviceID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/telemetries?deviceID=${deviceID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTelemetryPlot(ctx, path) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/telemetries/${path}?format=series`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTelemetryFile(ctx, path) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/telemetries/${path}`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestTelemetryFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/device/command/telemetry', data, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestTenMinTelemetryFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/device/command/tenmintelemetry', data, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    clearNotification(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/device/telemetries?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTwin(ctx, assetID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/twin?assetID=${assetID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTwin(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/device/twin', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapDevice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('device/swapDevice', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAssetHistory(ctx, assetID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/assetHistory?assetID=${assetID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAssetConfigHistory(ctx, assetID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/assetConfigHistory?assetID=${assetID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDeviceHistory(ctx, deviceID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/deviceHistory?deviceID=${deviceID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCommandHistory(ctx, deviceID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/commandHistory?deviceID=${deviceID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bulkUpdate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/device/bulktwinupdate', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineStatus(ctx, { lineID, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/status/${lineID}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAssetLineStatus(ctx, lineID, assetID, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/status/${lineID}/${assetID}`, params, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAlerts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/alerts', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reviewAlert(ctx, data) {
      const { id, typeID } = data
      return new Promise((resolve, reject) => {
        axios
          .post(`/asset/alerts?id=${id}&type=asset&typeID=${typeID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addToTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/training/training', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainings(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/training/training', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/training/training', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    triggerTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/training/training', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/training/training?id=${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitForTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/training/train?assetID=${data.assetID}&lag=${data.lag}&modelName=${data.modelName}`, data, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
