<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    :class="{'text-center': !isBusyRef}"
    style="overflow-x: auto;"
  >
    <app-echart-scatter
      :style-obj="{width: '100%', height: '20rem'}"
      :option-data="option"
    />
  </div>
</template>

<script>
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import AppEchartScatter from '@core/components/charts/echart/AppEchartScatter.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Papa from 'papaparse'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppEchartScatter,

  },
  props: {
    assetID: {
      type: Number,
      default: 0,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    timeRange: {
      type: Array,
      default: () => [],
    },
    largeData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const toast = useToast()
    const items = ref([])
    const option = ref({})
    const timeRangeRef = ref(props.timeRange)
    const id = ref(props.assetID)
    const isBusyRef = ref(props.isBusy)
    const large = ref(props.largeData)
    const width = ref('100%')

    const millisecondsPerDay = 1000 * 60 * 60 * 24

    const differenceInMilliseconds = Math.abs(timeRangeRef.value[1] - timeRangeRef.value[0])
    const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsPerDay)

    width.value = differenceInDays > 3 ? `${((differenceInDays / 3) * 100)}%` : '100%'

    const getItems = () => {
      isBusyRef.value = false
      store
        .dispatch('app-asset-log/fetchAssetdominantFreqLogs', {
          assetID: id.value, limit: 5000, startTime: timeRangeRef.value[0], endTime: timeRangeRef.value[1], timeout: 200000,
        })
        .then(response => {
          if (response.data) {
            items.value = response.data.data
            const minX = timeRangeRef.value[0]
            const maxX = timeRangeRef.value[1]
            const seriesData = response.data.freqSeries
            const minMag = 0 // response.data.minAmp
            const maxMag = 30 // response.data.maxAmp
            const { minFreq, maxFreq } = response.data
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              grid: [
                {
                  left: '5%',
                  top: '5%',
                  right: '2%',
                  bottom: '15%',
                },
              ],
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: false,
                  },
                  saveAsImage: {
                    pixelRatio: 2,
                  },
                  dataView: {
                    readOnly: false,
                  },
                },
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                position(pos, params, el, elRect, size) {
                  const obj = { top: 10 }
                  obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 50
                  console.log(+(pos[0] < size.viewSize[0] / 2))
                  return obj
                },
                extraCssText: 'width: 270px',
                formatter(param) {
                  const time = msEpochToDate(param[0].value[0])
                  const value1 = param[0].value
                  const value2 = param[1].value
                  const value3 = param[2].value
                  // prettier-ignore
                  return `
                          <div class="d-flex align-items-center">
                              Date: <div class="font-weight-bold;border:1px solid lightgray;">${time}</div>
                          </div>
                          <table style="text-align:left">
                              <tr>
                                  <th>Frequency</th>
                                  <th>Magnitude</th>
                              </tr>
                              <tr>
                                  <td style="text-align:left;border:1px solid lightgray;">${value1[1]}</td>
                                  <td style="text-align:left;border:1px solid lightgray;">${value1[2]}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;border:1px solid lightgray;">${value2[1]}</td>
                                  <td style="text-align:left;border:1px solid lightgray;">${value2[2]}</td>
                              </tr>
                              <tr>
                                  <td style="text-align:left;border:1px solid lightgray;">${value3[1]}</td>
                                  <td style="text-align:left;border:1px solid lightgray;">${value3[2]}</td>
                              </tr>
                          </table>
                      `
                },
              },
              axisPointer: {
                link: { xAxisIndex: 'all' },
                label: {
                  backgroundColor: '#777',
                },
              },
              dataZoom: [
                {
                  realtime: true,
                  type: 'inside',
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
                {
                  type: 'slider',
                  top: '85%',
                  realtime: true,
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
              ],
              xAxis: [
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  name: 'Time',
                  axisLine: {
                    onZero: false,
                  },
                  axisLabel: {
                    formatter(value) {
                      return msEpochToDate(value)
                    },
                  },
                },
              ],
              yAxis: [
                {
                  type: 'value',
                  min: (minFreq - 1),
                  max: (maxFreq + 1),
                  scale: false,
                  name: 'Frequency (Hz)',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  splitNumber: 5,
                  interval: 5,
                },
              ],
              visualMap: [
                {
                  top: 50,
                  right: 10,
                  min: minMag,
                  max: maxMag,
                  dimension: 2,
                  seriesIndex: 0,
                  show: false,
                  inRange: {
                    color: ['#28c76f', '#7D3C98', '#F1C40F', '#ff9f43', '#2980B9', '#ea5455'],
                  },
                  calculable: true,
                },
              ],
              series: [
                {
                  symbolSize: 3,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  data: seriesData,
                  large: !large.value,
                  type: 'scatter',
                  formatter(param) {
                    const { data } = param[0]
                    return [
                      `Open: ${data[0]}<br/>`,
                      `Close: ${data[1]}<br/>`,
                      `Lowest: ${data[2]}<br/>`,
                      `Highest: ${data[3]}<br/>`,
                    ].join('')
                  },
                },
              ],
            }
          } else {
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              title: {
                show: true,
                textStyle: {
                  color: 'grey',
                  fontSize: 20,
                },
                text: 'No Data',
                left: 'center',
                top: 'center',
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
            }
          }
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusyRef.value = false
        })
    }

    // timer.value = window.setInterval(() => {
    //   if (!isBusy.value) {
    //     setTimeout(getItems(), 0)
    //   }
    // }, 1000 * 10)

    getItems()

    const toCSV = () => Papa.unparse(items.value)

    const downLoadFile = () => {
      console.log(items.value)
      const content = toCSV()
      const blob = new Blob([content], { type: 'application/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${id.value}.csv`
      link.click()
      URL.revokeObjectURL(link.href)
    }

    return {
      getItems,
      isBusyRef,
      option,
      width,
      downLoadFile,
    }
  },
}
</script>
