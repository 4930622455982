<template>
  <b-card
    style="width: 100%; height: 20rem;"
    class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
  >
    <b-card-title>
      <strong class="text-black align-middle">Line Speed</strong>
    </b-card-title>
    <app-echart-doughnut
      :style-obj="{width: '100%', height: '20rem'}"
      :option-data="availabilityOption"
    />
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default ({
  components: {
    BCard,
    BCardTitle,
    AppEchartDoughnut,
  },
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const status = ref(props.status)
    watch(() => props.status, newVal => {
      status.value = newVal
    })

    const availability = ref(0)
    if (status.value === 2) {
      availability.value = 100
    } else if (status.value === 1) {
      availability.value = 50
    } else {
      availability.value = 0
    }
    const availabilityOption = ref({
      series: [
        {
          type: 'gauge',
          axisLine: {
            lineStyle: {
              width: 30,
              color: [
                [0.3, '#ea5455'],
                [0.7, '#ff9f43'],
                [1, '#28c76f'],
              ],
            },
          },
          pointer: {
            itemStyle: {
              color: 'auto',
            },
          },
          axisTick: {
            distance: -30,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 2,
            },
          },
          splitLine: {
            distance: -30,
            length: 30,
            lineStyle: {
              color: '#fff',
              width: 4,
            },
          },
          axisLabel: {
            color: 'auto',
            distance: 40,
            fontSize: 14,
          },
          detail: {
            valueAnimation: true,
            formatter: '{value} %',
            color: 'auto',
            fontSize: 18,
          },
          data: [
            {
              value: availability.value,
            },
          ],
        },
      ],
    })

    return {
      availabilityOption,
    }
  },
})
</script>
